<template>
    <section class="header">
        <div class="header__burger">
            <button class="header__burger_btn" @click="$emit('mobileMenuClicked')">
                <BurgerIcon/>
            </button>
        </div>
        <nav class="header__nav" ref="headerNav">
            <router-link class="header__icon" :to="{ name: 'Main'}">
                <img src="../assets/icons/logo.svg" alt="Logo Bonch.Dev">
            </router-link>
            <router-link
                class="header__nav-item"
                active-class="header__nav-item--active"
                v-for="(page, index) in pages"
                :key="index"
                :to="{ name: page.route, params: { direction: page.link }}"
            >
                {{page.title}}
            </router-link>
            <div class="header__number">
                <a class="phone" href="tel:+79214230325">+7 (921) 423-03-25</a>
            </div>
            <BaseButton class="header__btn" outline @click.native="$root.$emit('triggerModal', $event)">Оставить заявку</BaseButton>
        </nav>
    </section>
</template>

<script>
import BurgerIcon from './Icons/BurgerIcon'
export default {
    components: {
        BurgerIcon
    },
    methods: {
        showNav() {
            console.log(this.$refs.headerNav.classList)
            this.$refs.headerNav.classList.toggle('header__nav-visible')
        }
    },
    data() {
        return {
            pages: [
                {
                    title:"Разработка",
                    link:"development",
                    route: 'Direction'
                },
                {
                    title:"Дизайн",
                    link:"design",
                    route: 'Direction'
                },
                {
                    title:"Маркетинг",
                    link:"marketing",
                    route: 'Direction'
                },
                {
                    title:"Портфолио",
                    route: 'PortfolioList'
                },
                {
                    title:"Комплекс",
                    link:"complex",
                    route: 'Complex'
                },
                // {
                //     title:"Блог",
                //     link:"blog",
                //     route: 'Blog'
                // }
            ]
        }
    },
}
</script>

<style scoped>
.header__burger {
    border: none;
    background: transparent;
    display: none;

}
.header__burger_btn {
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
}
@media (max-width: 965px) {
    .header__burger {
        display: flex;
        align-items: flex-start;
    }
}


.header {
    box-sizing: border-box;
    width: 81.875rem;
    margin: 45px auto 0;
    box-sizing: border-box;
}
@media (max-width: 1367px) {
  .header {
    width: 100%;
    padding: 0 65px;
  }
}
@media (max-width: 1025px) {
  .header {
    padding: 0 40px;
  }
}
@media (max-width: 965px) {
  .header {
    margin: 30px auto 0;
  }
}
@media (max-width: 426px) {
  .header {
    padding: 0 25px;
  }
}
@media (max-width: 321px) {
  .header {
    padding: 0 15px;
  }
}
.header__nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: opacity ease 0.3s, visibility ease 0.25s, height ease 0.35s;
}
@media (max-width: 965px) {
    .header__nav {
        display: none;
    }
}
.header__icon {
    margin-right: 65px;
}

.header__nav-item {
    font-size: 16px;
    font-family: Raleway, serif;
    font-weight: 500;
    line-height: 140%;
    color: var(--light-black);
    display: flex;
    margin-right: 50px;
    text-decoration: none;
}
.header__nav-item--active {
    color: var(--black);
    font-weight: 600;
}

.header__number {
    font-size: 16px;
    line-height: 140%;
    text-align: right;
    color: var(--light-black);
    margin: 0 2.5em 0 auto;
}
@media (max-width: 1225px) {
    .header__icon {
        margin-right: 2.5em;
    }
    .header__nav-item {
        margin-right: 1.875em;
    }
    .header__number {
        margin: 0 1.875em 0 auto;
    }
}
@media (max-width: 1100px) {
    .header__icon {
        margin-right: var(--xxl);
    }
    .header__nav-item {
        margin-right: var(--xl);
    }
    .header__number {
        margin: 0 1.25em 0 auto;
    }
}
@media (max-width: 965px) {
    .header__icon {
        margin-right: 0;
        margin-bottom: var(--xxl);
    }
    .header__nav-item {
        margin-right: 0;
        margin-bottom: var(--xxl);
    }
    .header__number {
        margin: 0 0 var(--xxl) 0;
    }
    .header__btn {
        margin: 0 auto;
    }
}

.header__number .phone {
    text-decoration: none;
    color: var(--light-black);
}
</style>

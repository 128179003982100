<template>
  <div>
    <h2>Процесс</h2>
    <div class="process">
      <div class="process__items">
        <div
          v-for="(process, index) in processes"
          :key="index" class="process__item"
          @click="activeProcess = index"
          :class="{ 'process__item-dark': activeProcess === index }"
          :style="{ color: (activeProcess === index) ? `var(--${process.name}) !important` : `var(--black)` }"
        >
          <div class="process__number" v-text="index + 1"></div>
          <p class="process__info" v-text="process.briefText">Разработка технического задания</p>
        </div>
      </div>
      <div class="process__view">
        <span class="process__number" v-text="activeProcess + 1"></span>
        <p class="process__info" v-text="processes[activeProcess].fullText"></p>
        <transition name="fade">
          <img :src="imgUrl" :key="imgUrl" :alt="processes[activeProcess].briefText" class="process__img">
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    imgUrl() {
      return require(`../../assets/Processes/${this.processes[this.activeProcess].name}.svg`)
    }
  },
  data() {
    return {
      activeProcess: 0,
      processes: [
        {
          name: 'mobile',
          briefText: 'Разработка технического задания',
          fullText: `Проведение брифа с заказчиком, на котором определяется конечный результат и цель товара или услуги. Определяются промежуточные результаты работы и планы проекта. Техническое задание необходимо для грамотного планирования и оценки реализации проекта. В нем также указываются технологии, которые будут использоваться для реализации проекта.`
        },
        {
          name: 'smm',
          briefText: 'Подписание договора и внесение предоплаты',
          fullText: `На данном этапе происходит подписание договора на оказание необходимых в соотсветствии с ТЗ услуг. Также заказчик вносит предоплату в размере 25% от суммы заказа.`
        },
        {
          name: 'design-dev',
          briefText: 'Разработка дизайн-макетов',
          fullText: `Производится разработка дизайн-макетов для будущего Интернет-ресурса. Разработка осуществляется с помощью сервиса для разработки интерфейсов Figma. Если система разрабатывается на CMS, то осуществляется подключение необходимых плагинов. Далее дизайн согласовывается и утверждается. При необходимости вносятся правки.`
        },
        {
          name: 'seo',
          briefText: 'Техническое SEO и реализация дизайна',
          fullText: `Происходит реализация согласованного дизайна (вёрстка), а также подготовка серверной части проекта.`
        },
        {
          name: 'front',
          briefText: 'Подключение бизнес-логики и тестирование',
          fullText: `Реализация бизнес-логики проекта, а также тестирование работоспособности проекта, отладка.`
        },
        {
          name: 'back',
          briefText: 'Внесение правок и составление плана продвижения',
          fullText: `Проверка соответствия техническому заданию, внесение правок и размещение проекта на сервере заказчика, привязка домена. Также выдается сопроводительная информация по использованию системы, документация по API. По необходимости разрабатывается стратегия продвижения Интернет-ресурса: SMM, SEO.`
        }
      ]
    }
  }
}
</script>

<style scoped>
h2 {
  margin: var(--section-header-margin);
  position: relative;
}
.process {
  display: grid;
  grid-template-columns: 3.081fr 1.919fr;
  grid-gap: var(--lg);
  margin-bottom: var(--section-bottom-margin);
  position: relative;
  z-index: 10;
}
@media (max-width: 1162px) {
  .process {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 769px) {
  .process {
    grid-gap: 1.875em;
  }
}
@media (max-width: 375px) {
  .process {
    grid-gap: var(--lg);
  }
}


.process__items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: var(--lg);
}
@media (max-width: 769px) {
  .process__items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 1.875em;
  }
}
@media (max-width: 375px) {
  .process__items {
    grid-gap: var(--lg);
  }
}

.process__item {
  border: 3px solid var(--black);
  box-sizing: border-box;
  padding: var(--lg);
  min-height: 13.125em;
  color: var(--black);

  text-align: left;
  cursor: pointer;

  transition: all ease 0.2s;
}
.process__item:not(.process__item-dark):hover {
  border-color: var(--gray);
}
@media (max-width: 426px) {
  .process__item {
    padding: var(--lg) var(--md);
  }
}
@media (max-width: 376px) {
  .process__item {
    padding: var(--lg) var(--sm);
  }
}

.process__item-dark {
  border: 3px solid var(--black);
  background: var(--black);
}

.process__number {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  line-height: 4.6875rem;
  font-feature-settings: 'pnum' on, 'lnum' on;

  transition: all ease 0.2s;
}
.process__item:not(.process__item-dark):hover .process__number {
  color: var(--gray);
}

.process__info {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 140%;
  margin: var(--md) 0 0;

  transition: all ease 0.2s;
}
@media (max-width: 530px) {
  .process__info {
  font-size: 1rem;
  }
}
@media (max-width: 426px) {
  .process__info {
  font-size: 0.875rem;
  }
}
.process__item:not(.process__item-dark):hover .process__info {
  color: var(--light-black);
}

.process__view {
  display: flex;
  justify-content: flex-start;
  position: relative;

  background: var(--black);
  border: 3px solid var(--black);
  box-sizing: border-box;
  padding: 2.5em 2.8125em;
  color: #FFFFFF;

  text-align: left;
}
@media (max-width: 1162px) {
  .process__view {
    padding: 1.25em 5.3125em;
    height: calc(50em - 55vw);
  }
}
@media (max-width: 600px) {
  .process__view {
    padding: 1.25em 3.3125em 1.25em 3.3125em;
  }
}
@media (max-width: 426px) {
  .process__view {
    padding: 2.5em 1.5625em 2.5em 3.125em;
  }
}
@media (max-width: 376px) {
  .process__view {
    padding: 2.5em 1.25em 2.5em 2.5em;
  }
}

.process__view > .process__number {
  font-size: 2.25rem;
  line-height: 100%;
  margin-right: 0.4166em;
}
@media (max-width: 1162px) {
  .process__view > .process__number {
    margin-right: 0.83em;
  }
}
@media (max-width: 426px) {
  .process__view > .process__number {
    margin-right: var(--xl);
  }
}

.process__view > .process__info {
  font-size: 1rem;
  margin: 0;
}


.process__img {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  bottom: -3px;
  left: 0;
  right: 0;
  text-align: center;
}
@media (max-width: 426px) {
  .process__img {
  width: 100%;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>

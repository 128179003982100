<template>
    <div class="examples">
        <h2>Примеры работ</h2>
        <div class="slider">
            <div class="circles__design"></div>
            <div class="slider__infoblock">
                <div class="slider__infoblock__progectname">
                    <h4 class="slider__infoblock__progectname__title">{{examples[count].title}}</h4>
                    <div class="slider__infoblock__progectname__icon">
                        <img src="../../assets/examples/link.svg">
                    </div>
                </div>
                <div class="slider__infoblock__about">
                    <p>{{examples[count].text}}</p>
                    <p>{{examples[count].text2}}</p>
                    <p>{{examples[count].text3}}</p>
                    <div class="slider__infoblock__work">
                        <div v-for="(work, index) in examples[count].works" :key="index">{{work}}</div>
                    </div>
                </div>

                <div class="navigation">
                    <div class="navigation__info">
                        <div class="navigation__info__nmbr">
                            {{examples[count].number}}
                        </div>
                        <button class="navigation__info__previous" @click='previous'>
                            <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.51501 13.615L0.450012 11.55L12 0L23.55 11.55L21.485 13.615L12 4.13L2.51501 13.615Z" fill="#151515"/>
                            </svg>
                        </button>
                        <button class="navigation__info__next" @click='next'>
                            <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.51501 0.385L0.450012 2.45L12 14L23.55 2.45L21.485 0.385L12 9.87L2.51501 0.385Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div class="slider__column-right">
                <div class="slider__image">
                    <div class="slider__image_wrapper">
                        <img :src="SliderUrl">
                    </div>

                    <div class="navigation__progress">
                        <div class="check" :class="{ 'check-active': count + 1 === progress }" v-for="progress in 4" :key="progress">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            count: 0,
            examples: [
                {
                    title:'Teleport',
                    text:'Сервис для вызова и бронирования такси по всему миру. Для создания использовались следующие технологии: Vue, Golang, Laravel. Была выбрана сервис-ориентированная архитектура. Сервис развернут в облаке Managet Kubernetes от Яндекс.',
                    works:['Дизайн', 'Разработка'],
                    image: 'Teleport',
                    number:'01',
                },
                {
                  title:'SpicyDrop',
                  text:'Сайт открытых кейсов CS:GO с административной панелью и бот в сети STEAM для выдачи пользователям вещей. Проект связан с сервисами Game Money, SkinPAY, MarketCSGO.',
                  text2:'Зарегистрировано юридическое лицо для подключения к сервисам. В целях обеспечения безопасности сервис присоединен к системе Storm Wall, которая защитит от DDoS атак. Продуманы маркетинговая стратегия сайта и паблика ВК для продвижения проекта.',
                  text3:'Также есть бонусная, скидочные программы и рейтинг пользователей, чтобы стимулировать интерес к сервису.',
                  works:['Дизайн', 'Разработка', 'Продвижение'],
                  image: 'SpicyDrop',
                  number:'02',
                },
                {
                    title:'Geek Picnic',
                    text:'Геймифицированный сервис, подготовленный специально для мероприятия Geek Picnic. После регистрации в системе пользователи могут выполнять специальные задания, за что на их баланс начисляются баллы. Накопленную валюту можно обменять в виртуальном магазине на призы, подарки и мерч.',
                    works:['Дизайн', 'Разработка'],
                    image: 'GeekPicnic',
                    number:'03',
                },
                {
                    title:'Счастливый взгляд',
                    text:'Сервис электронной автоматизированной очереди для сети оптик "Счастливый взгляд". Использованы Vue и алгоритмы TTS. Сервис представлен в трех вариантах: стандартном, минималистичном и использованием рекламы.',
                    works:['Разработка'],
                    image: 'HappyLook',
                    number:'04',
                }
            ]
        }
    },
    computed: {
        SliderUrl() {
            return require(`../../assets/examples/` + this.examples[this.count].image + `.png`)
        },
    },
    methods: {
        next: function() {
            if ( this.count < 3) this.count = this.count + 1;
            else this.count = 0;
            return this.count
        },
        previous: function() {
            if ( this.count > 0) this.count = this.count - 1;
            else this.count = 3;
            return this.count
        },
    }
}
</script>

<style scoped>
.examples {
    padding: 14.375em 0;
    position: relative;
    z-index: 10;
}
@media (max-width: 769px) {
    .examples {
        padding: 5.3125em 0;
    }
}
@media (max-width: 321px) {
    .examples {
        padding: 5em 0;
    }
}
.circles__design{
    position: absolute;
    z-index: -100;
    width: 1065px;
    height: 1065px;
    left: -544px;
    top: -80px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(207, 230, 64, 0.8) 0%, rgba(73, 255, 124, 0.2) 70%, rgba(73, 255, 124, 0) 100%);
    opacity: 0.6;
}
.examples h2 {
    margin-bottom: 0.97em;
}
.slider {
    /* height: 600px; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.slider__infoblock {
    flex: 0 1 28.125em;
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
    padding-left: 65px;
    justify-content: space-between;
}
@media (max-width: 1367px) {
    .slider__infoblock {
        padding-left: 3vw;
    }
}
@media (max-width: 1025px) {
    .slider__infoblock {
        padding-left: 0;
    }
}
@media (max-width: 768px) {
    .slider__infoblock {
        flex-basis: 100%;
    }
}

.slider__infoblock__progectname {
    display: flex;
    flex-direction: row;

    margin-top: 5.9375em;
}
@media (max-width: 426px) {
    .slider__infoblock__progectname {
        margin-top: 0.3125em;
    }
}
.slider__infoblock__progectname__title {
    margin-right: var(--lg);
}
@media (max-width: 426px) {
    .slider__infoblock__progectname__title {
        font-size: 1.25rem;
        margin-right: 2em;
    }
}
.slider__infoblock__progectname__icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider__infoblock__about {
    min-height: 15.625em;
}
.slider__infoblock__about p {
    max-width: 28.125em;
    margin: 0;

    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 140%;
    text-align: left;
    color: var(--black);
    opacity: 0.7;

    margin-top: var(--lg);
}
@media (max-width: 426px) {
    .slider__infoblock__about p {
        font-size: 0.875rem;
        margin-top: var(--xl);
    }
}
.slider__infoblock__work {
    display: flex;
    flex-direction: row;

    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    color: var(--light-black);

    margin-top: 3.4375em;
}
@media (max-width: 426px) {
    .slider__infoblock__work {
        font-size: 0.875rem;
        margin-top: 2.857em;
    }
}
.slider__infoblock__work div {
    margin-right: 0.625em;
}

.slider__column-right {
    flex-grow: 1;
    flex-shrink: 0;
    width: 50%;
    max-width: 41.2em;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    margin-left: 1.25em;
}
@media (max-width: 768px) {
    .slider__column-right {
        width: 100%;
        max-width: none;
        margin-top: 2.5em;
        margin-left: 0;
    }
}

.slider__image {
    border: 3px solid var(--black);
    /* Тут пошли хаки, чтобы сделать этому блоку aspect-ratio 1:1 как в дизигне. По этой же причине ниже класс wrapper с абсолютным позиционированием, чтобы задать тегу img свойство object-fit. Но выглядит довольно аккуратно и понятно вроде. */
    width: 100%;
    padding-bottom: calc(544 / 600 * 100%);
    height: 0;
    position: relative;

    margin-right: 2.8125em;
}
@media (max-width: 1026px) {
    .slider__image {
        margin-right: 1.5625em;
    }
}
@media (max-width: 950px) and (min-width: 769px) {
    .slider__image {
       margin-top: 5.9375em;
    }
}
@media (max-width: 769px) {
  .slider__image {
    margin-right: 2.8125em;
  }
}
@media (max-width: 500px) {
  .slider__image {
    margin-right: 0.9375em;
  }
}
.slider__image_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1.5625em;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
@media (max-width: 500px) {
  .slider__image_wrapper {
    padding: 0;
  }
}
.slider__image_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.navigation{
    display: flex;
    flex-direction: column;
}
@media (max-width: 769px) {
  .navigation{
    margin-top: 5em;
  }
}
@media (max-width: 426px) {
  .navigation{
    margin-top: 2.5em;
  }
}
.navigation__info {
    display: flex;
    flex-direction: row;
}
.navigation__info__nmbr {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    color: var(--black);

    margin-right: 40px;
    min-width: 2rem;
}
.navigation__info__previous {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background: transparent;
    cursor: pointer;
}
.navigation__info__next {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--black);

    border: none;
    cursor: pointer;
}
.navigation__progress {
    display: flex;
    height: 100%;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: -2.8125em;
}
@media (max-width: 1026px) {
    .navigation__progress {
        right: -1.5625em;
    }
}
@media (max-width: 769px) {
  .navigation__progress {
    right: -2.8125em;
  }
}
@media (max-width: 500px) {
  .navigation__progress {
    right: -0.9375em;
  }
}
.check {
    height: 4.375em;
    width: var(--sm);
    background: var(--black);
    opacity: 0.2;
    transition: all ease 0.25s;
}
.check + .check {
    margin-top: var(--md);
}
.check-active {
    height: 6.125em;
    opacity: 1;
}
@media (max-width: 1026px) {
    .check {
        flex: 0 1 20%;
    }
    .check-active {
        flex: 0 0 23%;
    }
}

@media (max-width: 770px) {
    .examples {
        flex-direction: column;
    }
    .slider {
        flex-direction: column;
        height: 100%;
    }
}

@media (max-width: 325px) {
    .slider__infoblock__work {
        margin-top: 55px;
    }
}
</style>

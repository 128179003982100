<template>
  <div id="app" :class="{'app_fixed': isMobMenuActive}">
    <transition name="slide">
      <MobileMenu v-if="isMobMenuActive" :is-active="isMobMenuActive" @mobileMenuClicked="isMobMenuActive = !isMobMenuActive" />
    </transition>

    <Header @mobileMenuClicked="isMobMenuActive = !isMobMenuActive"/>
    <router-view/>
    <Footer />

    <BaseModal :show="isModalActive">
      <RequestForm @formSubmit="afterRequestForm" />
    </BaseModal>

    <BaseModal :show="isConfirmActive">
        <ConfirmRequest/>
    </BaseModal>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import MobileMenu from '@/components/Main/MobileMenu.vue'
import RequestForm from './components/RequestForm.vue'
import ConfirmRequest from "./components/ConfirmRequest";

export default {
  name: 'App',
  components: {
    ConfirmRequest,
    Header,
    Footer,
    MobileMenu,
    RequestForm
  },
  data() {
    return {
      isMobMenuActive: false,
      isModalActive: false,
      isConfirmActive: false,
    }
  },
  beforeMount() {

    this.$root.$on('triggerModal', ({ target } = {}) => {
      this.elTriggeredModal = target
      document.documentElement.classList.toggle("body-modal_visible");
      document.body.classList.toggle("body-modal_visible");
      this.isModalActive = !this.isModalActive
      this.isConfirmActive = false
      if (this.isModalActive) setTimeout(() => {
          document.getElementById('baseModal').querySelector('input').focus()
          document.getElementById('baseModal').style.display = 'flex'
      }, 0)
    })
  },
  beforeDestroy() {
    this.$root.$off('triggerModal')
  },
  methods: {
      afterRequestForm() {
          document.getElementById('baseModal').style.display = 'none'
          this.isConfirmActive = true;
          setTimeout( () => {
            this.isConfirmActive = false
          }, 5000)

      }
  }
}
 </script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: hidden;
}

.app_fixed {
  position: fixed;
}

body {
  margin: 0;
  /*position: relative;*/
  /*overflow-x: hidden;*/
}

/*@media (min-width: 1200px) {*/
/*  body.body-modal_visible {*/
/*    padding-right: 15px;*/
/*  }*/
/*}*/

:root {
  overflow-x: hidden;
  background: #F8F8F8;
}

:root.body-modal_visible {
  overflow-y: hidden;
  width: 100%;
}

@media (max-width: 1367px) {
  #content {
    width: 77.25em;
  }
}
@media (max-width: 1025px) {
  #content {
    width: 59em;
  }
}
@media (max-width: 769px) {
  #content {
    width: 43em;
  }
}
@media (max-width: 426px) {
  #content {
    width: 23.4375em;
  }
}
@media (max-width: 376px) {
  #content {
    width: 20.3125em;
  }
}
@media (max-width: 321px) {
  #content {
    width: 18.125em;
  }
}
</style>

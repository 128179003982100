import Vue from 'vue'
import App from './App.vue'
import './css/app.css'
import router from './router'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.config.productionTip = false
Vue.config.productionSourceMap = false

const requireComponent = require.context(
  './components/Base',
  false,
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')

  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.use(VueYandexMetrika, {
  id: 55018264,
  router: router,
  env: process.env.NODE_ENV
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

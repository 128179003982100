<template>
    <div>
        <h3>Заявка отправлена!</h3>
        <p>Мы свяжемся с вами в ближайшее время</p>
    </div>
</template>

<script>
export default {
    name: "ConfirmRequest"
}
</script>

<style scoped>

h3 {
    margin-bottom: 7px;

    text-align: center;
}

p {
    color: #858585;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}
</style>

<template>
   <div>
    <h2>Достижения</h2>
    <div class="achievements">
      <div class="achieve__photos">
        <div class="img__wrapper" id="bigphoto">
          <img src="../../assets/achievements/bigphoto.png" alt="Комманда Bonch.dev" srcset="">
        </div>
        <div class="img__wrapper" id="photo1">
          <img src="../../assets/achievements/photo1.png" alt="Коллектив Bonch.dev встречает первокурсников" srcset="">
        </div>
        <div class="img__wrapper" id="photo2">
          <img src="../../assets/achievements/photo2.png" alt="Вводная лекция школы Bonch.dev" srcset="">
        </div>
        <div class="img__wrapper" id="photo3">
          <img src="../../assets/achievements/photo3.png" alt="Команда Bonch.dev за проектированием" srcset="">
        </div>
        <div class="img__wrapper" id="photo4">
          <img src="../../assets/achievements/photo4.png" alt="Представитель Bonch.dev даёт интервью" srcset="">
        </div>
        <div class="img__wrapper" id="photo5">
          <img src="../../assets/achievements/photo5.png" alt="Команда Bonch.dev работает над проектом" srcset="">
        </div>
      </div>
      <div class="achieve__info">
        <section>
          <h4 class="achieve__header">Школа разработки Bonch.dev School</h4>
          <p class="achieve__text">
            Это образовательный проект от команды разработчиков “Bonch.dev”,
            призванный распространить любовь к современным технологиям и IT-разработке,
            передать опыт и знания, необходимые для работы.

            Школа проходит каждый год и подготовка осуществляется по следующим направлениям:
            UI/UX дизайн,
            web-разработка,
            мобильная разработка,
            SMM,
            SEO.
          </p>
          <a href="https://spinit.dev/" class="achieve__link">Сайт Школы</a>
        </section>
        <section>
          <h4 class="achieve__header">Хакатоны</h4>
          <p class="achieve__text">
            Наша команда принимает участие в большом количестве хакатонов, например:
            VK Hackathon, Хакатон HR 2019, HackGatchina и др.

            Мы считаем, что хакатоны очень важны для разработчиков,
            так как они приносят опыт создания продуктов,
            изучения новых технологий в короткие сроки и умения презентовать созданный продукт.
          </p>
          <div class="achieve__amount">
            <span><b>13</b> участий</span>
            <span><b>7</b> побед</span>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
h2 {
  margin: var(--section-header-margin);
}
@media (max-width: 785px) {
  h2 {
     margin: 0 0 2.5rem;
  }
}
@media (max-width: 426px) {
  h2 {
    font-size: 1.625rem;
  }
}

.achievements {
  display: flex;
  margin-bottom: var(--section-bottom-margin);
  position: relative;
  z-index: 10;
}

@media (max-width: 730px) {
  .achievements {
    flex-wrap: wrap;
  }
}

.achieve__photos {
  flex-grow: 1;
  min-width: 40em;
  display: grid;
  grid-template-areas:
    "bigphoto bigphoto photo1"
    "bigphoto bigphoto photo2"
    "photo3 photo4 photo5";
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--sm);
  /* width: 100%; */
  height: 100%;
  margin-right: 4.0625em;
}
@media (max-width: 1341px) {
  .achieve__photos {
    min-width: 26.625em;
    grid-template-areas:
      "bigphoto bigphoto"
      "bigphoto bigphoto"
      "photo1 photo2"
      "photo3 photo3"
      "photo3 photo3"
      "photo4 photo5";
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--md);
  }
}
@media (max-width: 730px) {
  .achieve__photos {
    min-width: 100%;
    width: 100%;
  }
}
@media (max-width: 785px) {
  .achieve__photos {
    margin-right: var(--lg);
  }
}
.achieve__photos img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

#bigphoto {
  grid-area: bigphoto;
}
#photo1 {
  grid-area: photo1;
}
#photo2 {
  grid-area: photo2;
}
#photo3 {
  grid-area: photo3;
}
#photo4 {
  grid-area: photo4;
}
#photo5 {
  grid-area: photo5;
}

.achieve__info {
  text-align: left;
  max-width: 33.9375em;
}
@media (max-width: 730px) {
  .achieve__info {
    margin-top: 2.1875em;
  }
}
@media (max-width: 425px) {
  .achieve__info {
    max-width: 18.125em;
  }
}

.achieve__info section:first-child {
  margin-bottom: 3.75em;
}
@media (max-width: 768px) {
  .achieve__info section:first-child {
    margin-bottom: 2.5em;
  }
}
@media (max-width: 425px) {
  .achieve__info section:first-child {
    margin-bottom: 2.1875em;
  }
}

.achieve__header {
  margin: 0 0 var(--lg);
}
@media (max-width: 426px) {
  .achieve__header {
    font-size: 1.25rem;
  }
}

.achieve__text {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  color: var(--light-black);

  margin: 0 0 var(--xl);
}
@media (max-width: 426px) {
  .achieve__text {
    font-size: 0.875rem;
  }
}

.achieve__link {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875em;
  line-height: 140%;

  text-decoration-line: underline;
  font-feature-settings: 'pnum' on, 'lnum' on;

  color: var(--front);
}

.achieve__amount {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
}
.achieve__amount b{
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 115%;
  /* identical to box height, or 28px */

  font-feature-settings: 'pnum' on, 'lnum' on;
}
.achieve__amount span:first-child {
  margin-right: var(--xxl);
}
</style>

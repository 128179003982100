<template >
  <div class="mobile-menu" v-on-clickaway="closeMobileMenu">
    <div class="mobile-menu__head">
      <div class="head__left">
        <router-link class="mobile-menu__logo" :to="{ name: 'Main'}">
          <LogoIcon class="mobile-menu__logo" @click.native="$emit('mobileMenuClicked')" />
        </router-link>
        <a class="mobile-menu__tel" href="tel:+79214230325">+7 (921) 423-03-25</a>
      </div>
      <div class="head__right">
        <button class="mobile-menu__burger_btn" @click="$emit('mobileMenuClicked')">
          <BurgerActiveIcon />
        </button>
      </div>
    </div>
    <nav class="mobile-menu__list">
      <router-link
        class="mobile-menu__item"
        active-class="mobile-menu__item--active"
        v-for="(page, index) in pages"
        :key="index"
        :to="{ name: page.route, params: { direction: page.link }}"
      >
        <div @click="$emit('mobileMenuClicked')">
          <component :is="page.icon"/>
          <span>{{page.title}}</span>
          <span>›</span>
        </div>
      </router-link>
    </nav>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import BurgerActiveIcon from '../Icons/BurgerActiveIcon'
import LogoIcon from '../Icons/LogoIcon'
import BlogMobileIcon from '../Icons/MobileMenu/ComplexMobileIcon.vue'
import CodeMobileIcon from '../Icons/MobileMenu/CodeMobileIcon.vue'
import ComplexMobileIcon from '../Icons/MobileMenu/DesignMobileIcon.vue'
import DesignMobileIcon from '../Icons/MobileMenu/ComplexMobileIcon.vue'
import PromotionMobileIcon from '../Icons/MobileMenu/PromotionMobileIcon.vue'

export default {
  components: {
    BurgerActiveIcon,
    LogoIcon,
    BlogMobileIcon,
    CodeMobileIcon,
    ComplexMobileIcon,
    DesignMobileIcon,
    PromotionMobileIcon
  },

  mixins: [clickaway],

  // props: {
  //   isActive: {
  //     type: Boolean,
  //     default: false
  //   }
  // },

  data() {
    return {
      pages: [
        {
            title:"Разработка",
            link:"development",
            route: 'Direction',
            icon: "CodeMobileIcon"
        },
        {
            title:"Дизайн",
            link:"design",
            route: 'Direction',
            icon: "DesignMobileIcon"
        },
        {
            title:"Маркетинг",
            link:"marketing",
            route: 'Direction',
            icon: "PromotionMobileIcon"
        },
        {
            title:"Портфолио",
            link:"portfolio",
            route: 'PortfolioList',
            icon: "DesignMobileIcon"
        },
        {
            title:"Комплекс",
            link:"complex",
            route: 'Complex',
            icon: "ComplexMobileIcon"
        },
        // {
        //     title:"Блог",
        //     link:"blog",
        //     route: 'Blog',
        //     icon: "BlogMobileIcon"
        // }
      ]
    }
  },

  methods: {
    closeMobileMenu: function() {
      this.$emit('mobileMenuClicked')
    },
  }
}
</script>

<style scoped>
.mobile-menu {
        display: none;
    }
  @media (max-width: 965px) {
    .mobile-menu {
        display: block;
    }
  }
  .mobile-menu {
    background-color: var(--light-black);
    width: 84%;
    height: 100%;
    padding: 1.875em 0;

    position: absolute;
    z-index: 1001;
    top: 0;
    left: 0;
    /*will-change: transform;*/
  }

  .mobile-menu__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 0 0.9375em 1.5625em;
    border-bottom: 0.4px solid rgba(255, 255, 255, 0.3);
  }

  .mobile-menu__head .head__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .mobile-menu__head .head__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .mobile-menu__burger_btn {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  .mobile-menu__logo {
    fill: var(--gray)
  }

  .mobile-menu__tel {
    font-size: 0.875rem;
    line-height: 140%;
    color: var(--white);
    text-decoration: none;

    margin-top: var(--sm);
  }


  .mobile-menu__list {
    display: flex;
    flex-direction: column;
    margin-top: 1.875em;
  }

  .mobile-menu__item div{
    width: 100%;
    height: 3.75em;
    padding: 0 0.9375em;
    box-sizing: border-box;

    display: flex;
    align-items: center;


    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 140%;
    text-decoration: none;
    color: var(--white);
  }

  .mobile-menu__item--active {
    background: #565656;
  }

  .mobile-menu__item{
    text-decoration: none;
  }

  .mobile-menu__item:active {
    background: #383737;
  }

  .mobile-menu__item div span:nth-child(2) {
    margin: 0 auto 0 1.5625rem;
  }
</style>

<template>
    <div class="footer">
        <div id="footer__block">
            <router-link class="footer__icon" :to="{ name: 'Main'}">
                <img src="../assets/icons/logo_light.svg" alt="">
            </router-link>
            <div class="left-block">
                <div class="services">
                    <div class="services__title">Услуги</div>
                    <router-link
                        class="services__list"
                        v-for="(service, index) of services"
                        :key="index"
                        :to="{ name: 'Direction', params: { direction: service.link }}"
                    >
                        {{service.title}}
                    </router-link>
                </div>
            </div>
            <div class="right-block">
                <div class="contact-block">
                    <div class="contact-block__title">Мы в соцсетях:</div>
                    <div class="social-media">
                        <a href="https://vk.com/bonch_dev" target="_blank">
                            <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.1843 1.14137C15.2893 0.792615 15.1843 0.536865 14.6878 0.536865H13.043C12.6245 0.536865 12.4333 0.758116 12.3283 1.00112C12.3283 1.00112 11.492 3.04037 10.307 4.36262C9.9245 4.74736 9.74975 4.86887 9.54125 4.86887C9.437 4.86887 9.2855 4.74737 9.2855 4.39862V1.14137C9.2855 0.722865 9.16475 0.536865 8.816 0.536865H6.2315C5.9705 0.536865 5.813 0.730365 5.813 0.914865C5.813 1.31087 6.4055 1.40237 6.46625 2.51837V4.93937C6.46625 5.46961 6.371 5.56637 6.161 5.56637C5.60375 5.56637 4.24775 3.51962 3.443 1.17662C3.28625 0.721365 3.128 0.537616 2.708 0.537616H1.064C0.59375 0.537616 0.5 0.758866 0.5 1.00187C0.5 1.43837 1.05725 3.59837 3.09575 6.45512C4.45475 8.40587 6.368 9.46337 8.111 9.46337C9.15575 9.46337 9.28475 9.22861 9.28475 8.82362V7.34912C9.28475 6.87962 9.3845 6.78512 9.71525 6.78512C9.95825 6.78512 10.3767 6.90812 11.3525 7.84787C12.467 8.96237 12.6515 9.46262 13.2778 9.46262H14.9218C15.3913 9.46262 15.626 9.22786 15.491 8.76436C15.3433 8.30311 14.8108 7.63187 14.1043 6.83762C13.7203 6.38462 13.1465 5.89712 12.9718 5.65337C12.728 5.33912 12.7985 5.20037 12.9718 4.92137C12.9725 4.92212 14.9758 2.10062 15.1843 1.14137Z" fill="#F8F8F8"/>
                            </svg>
                        </a>

<!--                        <a href="https://www.instagram.com/bonch.dev/?hl=en" target="_blank">-->
<!--                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                                <path d="M7.99996 5.65672C6.70973 5.65672 5.6568 6.70965 5.6568 7.99989C5.6568 9.29012 6.70973 10.3431 7.99996 10.3431C9.2902 10.3431 10.3431 9.29012 10.3431 7.99989C10.3431 6.70965 9.2902 5.65672 7.99996 5.65672ZM15.0277 7.99989C15.0277 7.02958 15.0365 6.06805 14.982 5.0995C14.9275 3.9745 14.6709 2.97606 13.8482 2.1534C13.0238 1.32899 12.0271 1.07411 10.9021 1.01961C9.9318 0.965122 8.97027 0.973912 8.00172 0.973912C7.03141 0.973912 6.06988 0.965122 5.10133 1.01961C3.97633 1.07411 2.97789 1.33075 2.15523 2.1534C1.33082 2.97782 1.07594 3.9745 1.02145 5.0995C0.966953 6.06981 0.975743 7.03133 0.975743 7.99989C0.975743 8.96844 0.966953 9.93173 1.02145 10.9003C1.07594 12.0253 1.33258 13.0237 2.15523 13.8464C2.97965 14.6708 3.97633 14.9257 5.10133 14.9802C6.07164 15.0347 7.03316 15.0259 8.00172 15.0259C8.97203 15.0259 9.93356 15.0347 10.9021 14.9802C12.0271 14.9257 13.0255 14.669 13.8482 13.8464C14.6726 13.022 14.9275 12.0253 14.982 10.9003C15.0382 9.93173 15.0277 8.9702 15.0277 7.99989ZM7.99996 11.6052C6.00484 11.6052 4.39469 9.99501 4.39469 7.99989C4.39469 6.00477 6.00484 4.39462 7.99996 4.39462C9.99508 4.39462 11.6052 6.00477 11.6052 7.99989C11.6052 9.99501 9.99508 11.6052 7.99996 11.6052ZM11.7529 5.08895C11.2871 5.08895 10.9109 4.71278 10.9109 4.24696C10.9109 3.78114 11.2871 3.40497 11.7529 3.40497C12.2187 3.40497 12.5949 3.78114 12.5949 4.24696C12.595 4.35757 12.5733 4.46712 12.5311 4.56934C12.4888 4.67156 12.4268 4.76443 12.3486 4.84265C12.2704 4.92086 12.1775 4.98288 12.0753 5.02514C11.9731 5.06741 11.8635 5.08909 11.7529 5.08895Z" fill="#F8F8F8"/>-->
<!--                            </svg>-->
<!--                        </a>-->

                        <a href="https://www.youtube.com/channel/UCw23j7ro8NRAehw-drZTlAw" target="_blank">
                            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.5463 2.20488C15.4566 1.87097 15.2808 1.56645 15.0365 1.32182C14.7922 1.07719 14.4879 0.901017 14.1541 0.810938C12.9254 0.480469 8 0.480469 8 0.480469C8 0.480469 3.07461 0.480469 1.8459 0.80918C1.51195 0.898967 1.2075 1.07504 0.963135 1.31972C0.718766 1.5644 0.543077 1.86906 0.453711 2.20312C0.125 3.43359 0.125 6 0.125 6C0.125 6 0.125 8.56641 0.453711 9.79512C0.634766 10.4736 1.16914 11.008 1.8459 11.1891C3.07461 11.5195 8 11.5195 8 11.5195C8 11.5195 12.9254 11.5195 14.1541 11.1891C14.8326 11.008 15.3652 10.4736 15.5463 9.79512C15.875 8.56641 15.875 6 15.875 6C15.875 6 15.875 3.43359 15.5463 2.20488ZM6.43555 8.35547V3.64453L10.5137 5.98242L6.43555 8.35547Z" fill="#F8F8F8"/>
                            </svg>
                        </a>

                    </div>
                    <div class="contact-info">
                        <a class="phone" href="tel:+79214230325">+7 (921) 423-03-25</a>
                        <a class="mail" href= "mailto:bonch.dev@bonch.dev">bonch.dev@bonch.dev</a>
                        <a class="telegram" href="https://t.me/d1kobrazzz" target="_blank">
                          tg: @d1kobrazzz
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            services: [
                {
                    title:"Разработка",
                    link:"development"
                },
                {
                    title:"Дизайн",
                    link:"design"
                },
                {
                    title:"Маркетинг",
                    link:"marketing"
                },
                // {
                //     title:"Комплекс",
                //     link:"complex"
                // }
            ],
        }
    },
}
</script>

<style scoped>
.footer {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: var(--gray);
    background: var(--black);

    padding-top: 30px;
    position: relative;
    z-index: 10;
}
#footer__block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 205px;
    width: 81.875rem;
    margin: 0 auto;
    box-sizing: border-box;
}
@media (max-width: 1367px) {
  #footer__block {
    width: 100%;
    padding: 0 65px;
  }
}
@media (max-width: 1025px) {
  #footer__block {
    padding: 0 40px;
  }
}
@media (max-width: 426px) {
  #footer__block {
    padding: 0 25px;
  }
}
@media (max-width: 321px) {
  #footer__block {
    padding: 0 15px;
  }
}
.footer a {
  text-decoration: none;
  color: var(--gray);
}
.footer a:hover {
  text-decoration: underline;
}
.footer__icon {
    margin-right: 80px;
}
@media (max-width: 768px) {
    .footer__icon {
        display: none;
    }
}
.left-block {
    display: flex;
    flex-direction: row;
}
@media (max-width: 768px) {
    .left-block {
        display: none;
    }
}
.services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 30px;
}
.services__title {
    color:#f8f8f8;

    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings: 'pnum' on, 'lnum' on;

    padding-bottom: var(--lg);
}
.services__list {
    margin-bottom: var(--sm);
}

.pages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 2.39rem;
}
.page__list {
    margin-bottom: var(--sm);
}
.right-block {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-end;
}
.contact-block__title {
    display: flex;
    justify-content: flex-end;
}
.social-media {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.social-media a {
    font-size: 0.875rem;
    display: flex;
    margin-left: var(--lg);
    margin-top: var(--md);
}
.social-media svg{
    margin: auto;
}
.contact-info {
    display: flex;
    flex-direction: column;
    margin-top: var(--xxl);
}
.phone {
    display: flex;
    justify-content: flex-end;
}
.mail {
    display: flex;
    justify-content: flex-end;
}
.telegram {
    display: flex;
    justify-content: flex-end;
}
</style>

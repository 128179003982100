<template>
  <div class="main">
    <MarketingInf />
    <Directions />
    <Examples />
    <Process />
    <Team />
    <Achievements />
  </div>
</template>

<script>
import Directions from '@/components/Main/Directions.vue'
import Process from '@/components/Main/Process'
import Achievements from '@/components/Main/Achievements.vue'
import Team from '@/components/Main/Team.vue'
import MarketingInf from '@/components/Main/MarketingInf'
import Examples from '@/components/Main/Examples'

export default {
  name: 'App',
  components: {
    Directions,
    Process,
    Achievements,
    Team,
    MarketingInf,
    Examples
  }
}
</script>

<style>
.main {
  width: 81.875em;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 1367px) {
  .main {
    width: 100%;
    padding: 0 65px;
  }
}
@media (max-width: 1025px) {
  .main {
    padding: 0 40px;
  }
}
@media (max-width: 426px) {
  .main {
    padding: 0 25px;
  }
}
@media (max-width: 321px) {
  .main {
    padding: 0 15px;
  }
}
</style>

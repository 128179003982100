<template>
  <span class="base__chip" :style="color">
    <slot></slot>
  </span>
</template>

<script>
const mainCardColors = {
  'marketing': '#00C0C0',
  'development': '#e8aa0b',
  'design': '#2fc319'
}
export default {
  props: {
    type: {
      type: String,
      default: 'marketing',
      validate: value => ['marketing', 'development', 'design'].includes(value)
    }
  },
  computed: {
    color() {
      return { color:  mainCardColors[this.type] }
    }
  }
}
</script>

<style scoped>
.base__chip {
  display: inline-block;
  background: #EAEAEA;
  padding: .286em .57em;

  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 140%;
}
</style>

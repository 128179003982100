<template>
  <div class="member" :class="{ 'member-chief': member.position === 'CEO' }">
    <div class="member__photo_wrapper">
      <img :src="imgUrl" :alt="member.position" class="member__photo">
    </div>
    <div class="member__info">
      <div class="member__position" v-text="member.position"></div>
      <div class="member__name" v-text="member.fullname"></div>
      <div class="member__link">
        <img src="../../assets/icons/tg_light.svg" alt="telegram" decoding="async" loading="lazy" srcset="">
        <a :href="`https://t.me/${member.tg}`" target="__blank" v-text="member.tg"></a>
      </div>
      <div class="member__link">
        <img src="../../assets/icons/email_light.svg" alt="e-mail address" srcset="">
        <a :href="`mailto:${member.email}`" v-text="member.email"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      required: true,
      type: Object
    }
  },
  computed: {
    imgUrl() {
      return require(`../../assets/team/${this.member.position}.png`)
    }
  }
}
</script>

<style scoped>
.member {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.member__photo_wrapper {
  width: 100%;
  height: 0;
  padding-bottom: calc(283 / 236 * 100%);
  position: relative;
  overflow: hidden;
}

img.member__photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.member-chief .member__photo_wrapper::before {
  content: '';
  position: absolute;
  display: block;
  z-index: 0;

  width: 401px;
  height: 401px;
  left: calc(50% - 200px);
  bottom: calc(0% - 200px);

  background: radial-gradient(50% 50% at 50% 50%, rgba(4, 165, 255, 0.8) 0%, rgba(73, 146, 255, 0) 100%);

}

.member__info {
  border: 1px solid var(--black);
  box-sizing: border-box;
  padding: var(--xxl) var(--lg) var(--xl);
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  color: white;
  background: var(--black);

  text-align: left;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 140%;
}

.member__position {
  color: var(--gray);
  line-height: 140%;
}

.member__name {
  font-weight: 400;
  font-size: 1rem;
  line-height: 140%;
  margin-bottom: 1.875em;
}
.member__link {
  display: flex;
  align-items: center;
}
.member__link:nth-child(3) {
  margin: auto 0 var(--sm);
}
.member__link a {
  text-decoration-line: underline;
  display: block;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: white;
  margin-left: 0.357em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>

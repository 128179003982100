<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" v-show="show" role="dialog" :aria-modal="show" @click.self="$root.$emit('triggerModal')">
        <div class="modal" ref="baseModal" id="baseModal">
          <button class="modal__close-btn close-btn" aria-label="Close modal" @click="$root.$emit('triggerModal')">
            <CloseIcon class="close-btn__icon"/>
          </button>

          <slot>
          </slot>

        </div>
    </div>
  </transition>
</template>

<script>
import CloseIcon from '../Icons/CloseIcon.vue'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CloseIcon
  },
  mounted() {
    document.onkeydown = (e) => {
        if (e.key === 'Escape' && this.show) this.$root.$emit('triggerModal')
        else if (e.key === 'Tab'&& this.show) {
        console.log(e)
          let focusable = this.$refs.baseModal.querySelectorAll('input,button,select,textarea');
          console.log(focusable)
          if (focusable.length) {
              let first = focusable[0];
              let last = focusable[focusable.length - 1];
              let shift = e.shiftKey;
              if (shift) {
                  if (e.target === first) { // shift-tab pressed on first input in dialog
                      last.focus();
                      e.preventDefault();
                  }
              } else {
                  if (e.target === last) { // tab pressed on last input in dialog
                      first.focus();
                      e.preventDefault();
                  }
              }
          }
        }
    };
  }
}
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  .modal-backdrop::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }

  .modal {
    position: relative;
    overflow-x: auto;
    display: flex;
    flex-direction: column;

    --width: min(37.5em, calc(100vw - 3em));

    width: var(--width);
    max-height: 85vh;
    overflow: auto;
    box-sizing: border-box;

    background-color: white;
    padding: 3.125em;
}

@media (max-width: 425px) {
  .modal {
    padding: 3.125em 1.5em;
  }
}

@media (max-width: 425px) {
  .modal {
    padding: 2em 1.5em;
  }
}

.close-btn {
  position: absolute;
  top: 2em;
  right: 2em;

  display: flex;

  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5em;
}

.close-btn__icon {
  fill: #C7C7C7;
  margin: auto;
}

 .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
    transform: translate(-50px);
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .3s ease, transform .3s ease;
  }
</style>

<template>
    <div>
        <h2 id="modal__title">Заявка</h2>

        <form class="modal__form" @submit.prevent ref="form">

            <div class="modal__selects">
                <select class="modal__select" v-model="form.direction" required>
                    <option disabled value="">Выберите направление</option>
                    <option value="development">Разработка</option>
                    <option value="marketing">Маркетинг</option>
                    <option value="design">Дизайн</option>
                </select>

                <select class="modal__select" v-if="form.direction === 'development'" v-model="form.option" required>
                    <option v-for="devOption in devOptions"
                            :key="devOption.value"
                            :value="devOption.value"
                            :disabled="devOption.disabled">
                        {{ devOption.text }}
                    </option>
                </select>

                <select class="modal__select" v-else-if="form.direction === 'marketing'" v-model="form.option" required>
                    <option v-for="marketingOption in marketingOptions"
                            :key="marketingOption.value"
                            :value="marketingOption.value"
                            :disabled="marketingOption.disabled">
                        {{ marketingOption.text }}
                    </option>
                </select>

                <select class="modal__select" v-else-if="form.direction === 'design'" v-model="form.option" required>
                    <option v-for="designOption in designOptions"
                            :key="designOption.value"
                            :value="designOption.value"
                            :disabled="designOption.disabled">
                        {{ designOption.text }}
                    </option>
                </select>
            </div>

            <div class="modal__inputs">
              <input class="modal__input"
                     v-model="form.name"
                     type="text"
                     name="name"
                     placeholder="Имя"
                     required>

              <input class="modal__input"
                     v-model="form.email"
                     type="email"
                     name="email"
                     placeholder="Email"
                     required>

              <input class="modal__input"
                     v-model="form.tel"
                     type="tel"
                     name="tel"
                     v-mask="'+# (###) ###-##-##'"
                     placeholder="Контактный телефон"
                     required>

              <input class="modal__input"
                     v-model="form.preference"
                     type="text"
                     name="preference"
                     placeholder="Предпочитаемый способ связи (телефон, почта и т.д.)"
                     required>

              <textarea class="modal__text-area"
                        v-model="form.comment"
                        name="text"
                        rows="5"
                        placeholder="Что бы вы хотели заказать?">
              </textarea>
            </div>

            <BaseButton class="modal__btn" outline @click.native="onSubmit">Оставить заявку</BaseButton>
        </form>
    </div>
</template>

<script>
import {VueMaskDirective} from 'v-mask'

export default {
    name: 'RequestForm',
    data() {
        return {
            form: {
                name: '',
                email: '',
                tel: '',
                preference: '',
                comment: '',
                direction: '',
                option: ''
            },
            devOptions: [
                {text: 'Выберите услугу', value: '', disabled: true},
                {text: 'Лендинг', value: 'landing'},
                {text: 'Многостраничный сайт', value: 'multipage'},
                {text: 'Интернет-магазин', value: 'shop'},
                {text: 'Индивидуальное решение', value: 'custom'},
                {text: 'Разработка на CMS', value: 'CMS'},
                {text: 'Поддержка систем и приложений', value: 'support'},
                {text: 'Кроссплатформенное приложение', value: 'cross_platform'},
                {text: 'iOS приложение', value: 'iOS'},
                {text: 'Android приложение', value: 'android'}
            ],
            marketingOptions: [
                {text: 'Выберите услугу', value: '', disabled: true},
                {text: 'SMM', value: 'smm'},
                {text: 'SEO', value: 'seo'},
                {text: 'Таргетированная реклама', value: 'target'},
                {text: 'Контекстная реклама', value: 'context'}
            ],
            designOptions: [
                {text: 'Выберите услугу', value: '', disabled: true},
                {text: 'Дизайн лендинга', value: 'landing'},
                {text: 'Дизайн сайта', value: 'multipage'},
                {text: 'Дизайн мобильного приложения', value: 'mobile'},
                {text: 'Брендинг', value: 'brand'},
                {text: 'Дизайн социальных сетей', value: 'social_media'}
            ],
        }
    },
    methods: {
        async onSubmit() {
            if (this.$refs.form.checkValidity()) {
                this.$emit('formSubmit')
                const { name, tel, email, preference, comment, direction, option } = this.form
                await fetch('https://order.bonch.dev/message', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        "name": name,
                        "phone": tel.replaceAll(/([(,),\-, ])/g, ''),
                        "email": email,
                        "preferred_channel": preference,
                        "direction": direction,
                        "option": option,
                        "message": comment
                    })
                });
                this.clearForm();
            }
        },
        clearForm() {
            this.form.name = ''
            this.form.email = ''
            this.form.tel = ''
            this.form.preference = ''
            this.form.comment = ''
            this.form.direction = ''
            this.form.option = ''
        }
    },
    watch: {
        "form.direction": {
            handler() {
                this.form.option = '';
            }
        }
    },
    directives: {mask: VueMaskDirective}
}
</script>

<style scoped>
.modal__description {
    margin: 0.357em 0 1.7857em;
    font-family: Raleway;
    font-size: 0.875rem;
    line-height: 140%;
    color: var(--gray);
}

.modal__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.modal__selects {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.modal__selects > * {
    margin-right: 0.9375em;
    margin-bottom: 0.5em;
}

.modal__select {
    background: transparent;
    border: none;

    font-family: Raleway;
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;

    width: 100%;
    height: 2.4em;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='inherit'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 8px;
    background-position: calc(100% - 5px) calc(50% + 2px);
    background-color: transparent;
    padding-right: 20px;
}

.modal__inputs {
    display: flex;
    flex-direction: column;
    margin: 0 0 1.875em;
    width: 100%;
}

.modal__input,
.modal__text-area {
    padding: 0.9375em 1.42857em;
    border: none;
    background: var(--white);
    resize: none;

    font-family: Raleway;
    font-size: 1rem;
    line-height: 140%;
}

.modal__input {
    margin-bottom: 0.625em;
}
</style>

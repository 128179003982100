<template>
  <button class="base-btn" :class="{ 'base-btn--outline': outline }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    outline: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.base-btn {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  border: none;
  font-size: 1rem;
  line-height: 140%;
  padding: 0.8125em 3em;
  border: 2px solid var(--black);
  align-self: flex-start;
  cursor: pointer;

  box-sizing: border-box;
  /* max-height: 100%; */

  background: var(--black);
  color: #ffffff;
}

.base-btn:hover {
  background: var(--black);
  color: #ffffff;
}

.base-btn:active {
  color: #898989;
}

.base-btn:hover {
  background: var(--mobile);
}

.base-btn:active {
  color: #EAEAEA;
}


.base-btn--outline {
  color: var(--black);
  background: transparent;
}
.base-btn--outline:hover {
  background: var(--black);
  color: #ffffff;
}

.base-btn--outline:active {
  color: #898989;
}
</style>

<template>
<router-link class="direction" :to="{ name: 'Direction', params: { direction: direction.link }}">
  <h3 v-text="direction.title"></h3>
  <ul class="direction__list">
    <li v-for="(point, index) in direction.points" :key="index">{{ point }}</li>
  </ul>
  <component class="direction__svg" :is="direction.icon"></component>
</router-link>

</template>

<script>
import DevelopmentIcon from './../Icons/DevelopmentIcon.vue'
import DesignIcon from './../Icons/DesignIcon.vue'
import PromotionIcon from './../Icons/PromotionIcon.vue'
export default {
  components: { DevelopmentIcon, DesignIcon, PromotionIcon },
  props: {
    direction: {
      required: true,
      type: Object
    }
  }
}
</script>

<style scoped>
  .direction {
    width: calc(100% - 6px);
    height: calc(30em - 6px);
    margin: 0 3px;
    padding: 1.875em var(--xxl) 0;
    box-sizing: border-box;
    text-decoration: none;

    position: relative;
    z-index: 1;
    overflow: hidden;

    outline: 3px solid var(--black);
    text-align: left;

  }
  @media (max-width: 1367px) {
    .direction {
      height: calc(27.4375em - 6px);
    }
  }
  @media (max-width: 1025px) {
    .direction {
      height: calc(25em - 6px);
    }
  }
  @media (max-width: 900px) {
    .direction {
      height: calc(30em - 6px);
    }
  }
  @media (max-width: 426px) {
    .direction {
      height: calc(27.4375em - 6px);
    }
  }
  @media (max-width: 340px) {
    .direction {
      height: calc(25.125em - 6px);
    }
  }

  .direction:nth-child(1):hover,
  .direction:nth-child(1):focus {
    outline-color: var(--front);
  }
  .direction:nth-child(2):hover,
  .direction:nth-child(2):focus {
    outline-color: var(--design-dev);
  }
  .direction:nth-child(3):hover,
  .direction:nth-child(3):focus {
    outline-color: var(--seo);
  }

  .direction::after {
    display: none;
    position: absolute;
    width: 267px;
    height: 267px;
    left: 194px;
    top: 312px;
    bottom: 0;
    right: 0;
    content: '';
    filter: blur(250px);
  }
  .direction:nth-child(1)::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(231, 138, 29, 0.8) 0%, rgba(0, 163, 255, 0) 100%);
  }
  .direction:nth-child(2)::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(46,	255,	12, 0.8) 0%, rgba(239, 49, 209, 0) 100%);
  }
  .direction:nth-child(3)::after {
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 192, 192, 0.8) 0%, rgba(209, 99, 99, 0) 100%);
  }

  .direction:hover::after,
  .direction:focus::after {
    display: block;
  }

  .direction h3 {
    margin: 0 0 var(--xxl);
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  @media (max-width: 340px) {
    .direction h3 {
    font-size: 1.25rem;
    }
  }

  .direction__list{
    font-family: Arial;
    font-style: normal;
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 140%;

    color: var(--light-black);
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .direction__list li + li {
    margin-top: var(--lg);
  }

  @media (max-width: 340px) {
    .direction__list {
      font-size: 0.875rem;
    }
  }

  .direction__svg {
    width: 66.5%;
    height: auto;
    position: absolute;
    bottom: -15%;
    right: -13.5%;
    z-index: 100;
    fill: var(--light-black);
  }
  .direction:nth-child(1):hover .direction__svg,
  .direction:nth-child(1):focus .direction__svg {
    fill: var(--front);
  }
  .direction:nth-child(2):hover .direction__svg,
  .direction:nth-child(2):focus .direction__svg {
    fill: var(--design-dev);
  }
  .direction:nth-child(3):hover .direction__svg,
  .direction:nth-child(3):focus .direction__svg {
    fill: var(--seo);
  }
  @media (max-width: 1367px) {
    .direction__svg {
      /* height: calc(18.875em - 3px); */
    }
  }
  @media (max-width: 1025px) {
    .direction__svg {
      width: 60%;
    }
  }
  @media (max-width: 900px) {
    .direction__svg {
      width: 17.1875em;
      right: -7.5%;
    }
  }
  @media (max-width: 470px) {
    .direction__svg {
      width: 66.5%;
    }
  }
  @media (max-width: 426px) {
    .direction__svg {
      /* height: calc(18.3125em - 3px); */
    }
  }
  @media (max-width: 376px) {
    .direction__svg {
      /* height: calc(16.0625em - 3px); */
    }
  }
  @media (max-width: 321px) {
    .direction__svg {
      /* height: calc(var(--section-bottom-margin) - 3px); */
    }
  }

</style>

<template>
  <div class="team-box">
    <h2>Команда Bonch.dev</h2>

    <div class="team">
      <div class="circle__seo-second"></div>

      <TeamMember class="team__member" v-for="(member, index) in team" :key="index" :member="member"/>
    </div>

    <div class="team__addition">
      <div class="circles__frontend"></div>

      <div class="team__addition_text">
        <div class="team__addition_numbers">
          <span>57 человек</span>
          <div class="team__exp">стаж 1-5 лет</div>
        </div>
        <p>
          Наша команда включает в себя большое количество специалистов разного уровня подготовки. Сотрудники Bonch.dev уделяют много времени саморазвитию и совершенствованию в своих и смежных областях. Мы готовы предоставить специалистов для задач различных уровней сложности.
        </p>
      </div>
      <div class="team__addition_request">
        <p>Мы предоставляем услуги аутстаффинга для специалистов любых направлений</p>
        <button class="team__btn" @click="$root.$emit('triggerModal', $event)">
            Оставить заявку
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import TeamMember from './TeamMember.vue'
export default {
  components: { TeamMember },
  data() {
    return {
      team: [
        {
          position: 'CEO',
          fullname: 'Владимир Черномырдин',
          tg: 'd1kobrazzz',
          email: 'd1kobraz@bonch.dev',
        },
        {
          position: 'CTO',
          fullname: 'Георгий Каргин',
          tg: 'Vireli',
          email: 'kargin.go@bonch.dev',
        },
        // {
        //   position: 'COO',
        //   fullname: 'Глеб Глазков',
        //   tg: 'eye_kov',
        //   email: 'glazkov@bonch.dev',
        // },
        // {
        //   position: 'CFO',
        //   fullname: 'Дмитрий Жуков',
        //   tg: 'ZhukovDS',
        //   email: 'zhukov.d@bonch.dev',
        // },
        {
          position: 'CMO',
          fullname: 'Денис Вахрушев',
          tg: 'dinich94',
          email: 'vakhrushev.d@bonch.dev',
        },
      ]
    }
  }
}
</script>

<style scoped>
h2 {
  margin: var(--section-header-margin);
}
@media (min-width: 426px) and (max-width: 769px) {
  h2 {
    margin: 0 0 4.0625rem;
  }
}
@media (max-width: 426px) {
  h2 {
    font-size: 1.625rem;
  }
}
.team-box {
  margin-bottom: var(--section-bottom-margin);
}
.team {
  display: grid;
  grid-template-columns: repeat(5, max(300px ,calc(20% - (var(--md) * 4 / 5))));
  gap: var(--md);
  margin-bottom: 4.375em;
  position: relative;
  z-index: 10;
}
@media (max-width: 1367px) {
  .team {
    grid-template-columns: repeat(5,  max(300px ,calc(20% - (var(--lg) * 4 / 5))));
    gap: var(--lg);
  }
}
@media (max-width: 1161px) {
  .team {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 769px) {
  .team {
    grid-template-columns: repeat(2, calc(50% - var(--lg) / 2));
  }
}
@media (max-width: 490px) {
  .team {
    grid-template-columns: 1fr;
  }
}

.team__member {
  width: 100%;
}

.circle__seo-second {
  position: absolute;
  z-index: -1000;
  left: -150px;
  top: -150px;
  width: 810px;
  height: 810px;
  background: radial-gradient(50% 50% at 50% 50%, #34BEBE 0%, rgba(73, 168, 255, 0) 100%);
  opacity: 0.6;
}
.circles__frontend {
  position: absolute;
  z-index: -1000;
  /*left: 850px;*/
  /*top: 350px;*/
  top: -200px;
  width: 799px;
  height: 799px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 184, 0, 0.8) 0%, rgba(255, 73, 73, 0) 100%);
  opacity: 0.4;
  right: -200px;
}
.team__addition {
  position: relative;
  z-index: 10;

  font-family: Raleway;
  font-style: normal;
  font-size: 1rem;
  line-height: 140%;
  text-align: left;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
@media (max-width: 1367px) {
  .team__addition {
    padding: 0 2.3125em;
  }
}
@media (max-width: 638px)  {
  .team__addition {
    padding: 0;
  }
}

.team__addition_text {
  display: flex;
}
@media (max-width: 638px)  {
  .team__addition_text {
    flex-direction: column;
    align-items: start;
  }
}

.team__addition_numbers {
  margin-right: 3.125em;
}
@media (max-width: 638px)  {
  .team__addition_numbers {
    margin: 0 0 1.875em;
  }
}
@media (min-width: 638px)  {
  .team__addition_numbers {
    min-width: 10.9375em;
  }
}

.team__addition_numbers span {
  display: block;
  font-weight: bold;
  font-size: 2rem;
  line-height: 120%;
  color: var(--black);
  margin-bottom: var(--sm);
}
@media (max-width: 426px)  {
  .team__addition_numbers span {
    font-size: 1.625rem;
    margin-bottom: var(--xl);
  }
}

.team__addition_numbers .team__exp {
  font-weight: 500;
  text-align: right;

  color: var(--light-black);
}
.team__addition p {
  font-family: Arial;
  font-weight: normal;
  color: var(--light-black);
  margin: var(--md) 0 0;
  max-width: 34.3125em;
}
@media (max-width: 426px)  {
  .team__addition p {
    font-size: 0.875em;
    margin: 0;
  }
}

.team__addition_request {
  margin-left: auto;
  max-width: 19.8125em;
  text-align: right;
  align-self: flex-end;
}
@media (max-width: 878px) {
  .team__addition_request {
    margin-top: 1.875em;
  }
}

.team__addition_request p  {
  margin: 0 0 var(--xl);
  text-align: right;
}

.team__btn {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  color: var(--black);

  border: 2px solid var(--black);
  box-sizing: border-box;
  padding: var(--lg) 3.125em;

  background: transparent;
  cursor: pointer;
}
</style>

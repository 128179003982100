<template>
  <div class="directions">
    <Direction v-for="(direction, index) in directions" :direction="direction" :key="index"/>
  </div>
</template>

<script>
import Direction from '@/components/Main/Direction.vue'
export default {
  components: { Direction },
  data() {
    return {
      directions: [
        {
          icon: 'DevelopmentIcon',
          title: 'Разработка',
          link: 'development',
          points: ['Сайты', 'Мобильные приложения', 'Devops', 'Front-end', 'Back-end']
        },
        {
          icon: 'DesignIcon',
          title: 'Дизайн',
          link: 'design',
          points: ['UX/UI', 'Архитектура продукта', 'Брендинг', 'Оформление соц. сетей', 'Исследования', 'Моушен-дизайн']
        },
        {
          icon: 'PromotionIcon',
          title: 'Маркетинг',
          link: 'marketing',
          points: ['SEO', 'Маркетинг', 'Семантика']
        }
      ]
    }
  }

}
</script>

<style scoped>
.directions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--xxl);
  width: 100%;
  position: relative;
  z-index: 10;
}
@media (max-width: 900px) {
  .directions {
    grid-template-columns: 1fr;
  }
}
.circles__seo {
  position: absolute;
  z-index: -1000;
  left: 540px;
  top: -250px;
}
@media (max-width: 1024px) {
  .directions {
  grid-gap: var(--xl);
  }
}

@media (max-width: 1440px) {
  .circles__seo {
  left: 550px;
  }
}
</style>

import Main from '../views/Main.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/directions/:direction',
    name: 'Direction',
    component: () => import(/* webpackChunkName: "direction" */ '../views/Direction.vue'),
    props: true
  },
  {
    path: '/services/:direction/:service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "service" */ '../views/Service.vue'),
    props: true
  },
  {
    path: '/portfolio',
    name: 'PortfolioList',
    component: () => import(/* webpackChunkName: "PortfolioList" */ '../views/PortfolioList.vue')
  },
  {
    path: '/portfolio/:id',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portfolio.vue'),
    props: true
  },
  // {
  //   path: '/blog',
  //   name: 'Blog',
  //   component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue'),
  // },
  {
    path: '/complex',
    name: 'Complex',
    component: () => import(/* webpackChunkName: "complex" */ '../views/Complex.vue'),
  },
  {
    path: '/blog/:id',
    name: 'Article',
    component: () => import(/* webpackChunkName: "article" */ '../views/Article.vue'),
  }
]

export default routes
